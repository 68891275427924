<template>

    <!-- Hero Section -->
    <section id="intro" class=" pb-8 flex items-center justify-center relative bg-cover bg-center" style="background-image: url('river.jpeg'); padding-top: 100px; padding-bottom: 100px;">
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="absolute inset-0 bg-radial-gradient"></div>
    <div class="text-center max-w-4xl px-6 relative z-10 flex flex-col items-center justify-center h-full">
      <i class="fas fa-tree text-6xl mb-4"></i>
      <!-- <img src="logo.png" alt="Map Image" class="my-6 " height="240px" width="240px"> -->

      <h1 class="text-5xl font-bold mb-4">Protect Presumpscot</h1>
      <p class="text-lg mb-6"><i class="fas fa-map-marker-alt mr-2"></i> A conservation oriented nonprofit in Portland, Maine</p>
      <p class="text-lg mb-4 italic">
        <i class="fas fa-leaf mr-2"></i> 
        Our mission is “To advocate for protecting the environmental health and ecological integrity of the Presumpscot River Preserve, the adjacent section of the Presumpscot River, and maintaining the natural experience of the Presumpscot River Trails network.”
      </p>
      
      <div class="space-x-4 mt-6">
        <a href="#learnmore" class="inline-flex items-center justify-center bg-neutral-300 text-gray-900 px-6 py-3 rounded-md transform hover:scale-105 transition-transform duration-300 ease-in-out">
          <i class="fas fa-info-circle mr-2"></i> Learn More
        </a>
        <a href="#getinvolved" class="inline-flex items-center justify-center bg-blue-200 px-6 py-3 rounded-md transform hover:scale-105 transition-transform duration-300 ease-in-out">
          <i class="fas fa-hand-holding-heart mr-2"></i> Get Involved
        </a>
      </div>
      <p class="text-md mb-6 mt-8">
        Additionally, we advocate for low-impact development and preservation of public and natural resources in spaces of consequence to the Presumpscot Preserve and its trails network.
      </p>

      <!-- Board Meeting Section -->
      <div class="bg-white bg-opacity-75 p-4 rounded-md shadow-md mt-2">
        <h2 class="text-2xl font-semibold mb-2"><i class="fas fa-calendar-alt mr-2"></i> Hybrid Planning Board Meeting</h2>
        <p class="text-md mb-2">Join us for the next planning board meeting to discuss the development proposal. <br><i>The meeting will be held in person and on Zoom.</i></p>
        <p class="text-md mb-1 flex items-center justify-center">
          <i class="fas fa-calendar-day mr-1"></i> <strong>October 22, 2024</strong>
          <span class="mx-2">|</span>
          <i class="fas fa-clock mr-1"></i> <strong>4:30 PM</strong>
        </p>
        <p class="text-md mb-2">
          <i class="fas fa-map-marker-alt mr-1"></i>
          <a href="https://maps.app.goo.gl/e7j6sdYDMC3RhkMPA" class="no-underline">
        <b>Portland City Hall</b>, Room 24 (Basement Level)
          </a>
        </p>
        <div class="mt-4 flex justify-center space-x-4">
          <a 
        href="https://www.google.com/calendar/render?action=TEMPLATE&text=Hybrid+Planning+Board+Meeting&dates=20241022T203000Z/20241022T223000Z&details=Join+us+for+the+next+planning+board+meeting+to+discuss+the+development+proposal.&location=Portland+City+Hall,+Room+24+(Basement+Level)&sf=true&output=xml"
        target="_blank"
        class="inline-flex items-center justify-center bg-blue-200 text-blue-900 px-4 py-2 rounded-md transform hover:scale-105 transition-transform duration-300 ease-in-out"
          >
        <i class="fas fa-calendar-plus mr-2"></i> Add to Calendar
          </a>
          <a 
        href="https://portlandme.portal.civicclerk.com/event/6985/overview"
        target="_blank"
        class="inline-flex items-center justify-center bg-gray-200 text-black-900 px-4 py-2 rounded-md transform hover:scale-105 transition-transform duration-300 ease-in-out"
          >
        <i class="fas fa-info-circle mr-2"></i> View Agenda and Details
          </a>
        </div>
      </div>
    </div>
</section>

    <!-- Section 1 -->
    <section id="learnmore" class="min-h-screen flex items-center justify-center bg-stone-200 text-stone-800 py-12">
      <div class="max-w-3xl mx-4 sm:mx-6 lg:mx-auto">
        <div class="text-center">
          <h2 class="text-4xl font-semibold mb-4">
            <i class="fas fa-info-circle mr-2"></i> Learn More
          </h2>
          <p class="text-lg mb-6">
            There is currently a 
            <a href="https://selfservice.portlandmaine.gov/EnerGov_Prod/SelfService#/plan/d88a5a32-8416-4189-927f-1a7e6cd6f84b" class="text-blue-500 underline hover:text-blue-700">major site development proposal</a> 
            for a 13 acre lot off Hope Ave and abutting the Presumpscot river preserve with planned duplex and triplex units positioned as 
            <span class="font-bold italic rounded">close as 25ft from the North Loop and Brandy Lane connector trails</span> 
            and 
            <span class="font-bold italic rounded">500ft uphill from the river itself</span>.
          </p>
        </div>
        <div class="text-center">
          <img @click="openModal('map.png')" src="map.png" alt="Map Image" class="mt-6 border border-gray-300 shadow-lg rounded-md cursor-pointer">
          <img @click="openModal('map2.png')" src="map2.png" alt="Map Image 2" class="mt-6 border border-gray-300 shadow-lg rounded-md cursor-pointer">
        </div>
        <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        <div class="flex flex-wrap justify-center">
          <div 
            v-for="image in images" 
            :key="image" 
            class="w-32 h-32 m-4 border-2 border-gray-300 rounded-md cursor-pointer overflow-hidden bg-cover bg-center"
            @click="openGalleryModal(image)"
            :style="{ backgroundImage: `url(${image})` }"
          ></div>
        </div>
        </div>
        <div class="mt-8">
          <h3 class="text-2xl font-semibold mb-4">Protect Presumpscot is concerned about:</h3>
          <ul class="list-disc list-inside text-lg pl-4 mb-6">
            <li>Deterioration of the Presumpscot Preserve’s natural experience</li>
            <li>Loss of wetlands and wooded habitat within city limits</li>
            <li>Construction erosion damaging the Presumpscot Preservation land and River</li>
            <li>Construction debris blowing onto the Presumpscot River Preserve and into the River</li>
            <li>Planned retention ponds positioned against a 30% embankment which as proposed are not to code</li>
            <li>Stormwater runoff and overflow damaging the Presumpscot Preserve ecosystem</li>
          </ul>
          <p class="text-lg mb-6">A Miami based real estate developer with a troublesome track record in Maine is pursuing this project.  
            Read more about the developer’s history with another Maine project <a href="https://www.pressherald.com/2023/12/10/saco-retirement-development-plagued-by-problems-one-resident-compares-to-a-living-hell/" class="text-blue-500 underline hover:text-blue-700">here</a></p>
        </div>
        
      </div>
    </section>
    
    <!-- Modal -->
    <div v-if="isModalOpen" @click="closeModal" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
      <img :src="modalImage" alt="Enlarged Image" class="max-w-full max-h-full rounded">
    </div>

        <!-- Gallery Modal -->
        <div v-if="isGalleryModalOpen" @click="closeGalleryModal" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center gallery-modal">
      <img :src="galleryModalImage" alt="Enlarged Image" class="max-w-full max-h-full rounded">
    </div>


      <!-- Section 2 -->
      <section id="getinvolved" class="min-h-screen flex items-center justify-center py-12">
      <div class="max-w-3xl mx-4 sm:mx-6 lg:mx-auto">
        <div>
          <h2 class="text-4xl font-semibold mb-4 text-center">
            <i class="fas fa-hand-holding-heart mr-2"></i> We’re asking for your help!
          </h2>
          <div class="mt-8 mb-6">
            <h3 class="text-2xl font-semibold mb-4 text-gray-800">Please donate!</h3>
            <ul class="list-disc list-inside text-lg pl-4 mb-6">
              <li class="flex items-center mb-2">
                <i class="fas fa-donate text-green-500 mr-2"></i>
                GoFundMe: <a href="https://www.gofundme.com/f/help-ensure-low-impact-development-near-the-preserve?utm_medium=copy_link_all&utm_source=customer&utm_campaign=p_cp%2Bshare-sheet&attribution_id=sl%3A2de5654b-ffb5-4e1f-ab8e-ac9e4c3800b2" class="text-blue-500 underline hover:text-blue-700 ml-1">Protect the Presumpscot Trail</a>
              </li>
              <li class="flex items-center mb-2">
                <i class="fas fa-wallet text-blue-500 mr-2"></i>
                Venmo: @ProtectPresumpscot
              </li>
              <li class="flex items-center">
                <i class="fab fa-paypal text-blue-600 mr-2"></i>
                PayPal: @ProtectPresumpscot
              </li>
            </ul>
          <h4 class="text-xl font-semibold mb-4">All donations will be used to…</h4>
          <ul class="list-disc list-inside text-lg pl-4">
            <li>Pay professionals to independently evaluate this proposed development’s impact on the Preserve’s ecosystem</li>
            <li>Retain legal representation at Portland Planning Board meetings to ensure all ecological concerns are voiced to the Portland Planning Board in a formal and informed manner</li>
            <li>Raise funds to help purchase this lot from the developer for purposes of conservation in conjunction with other publicly available funds like Portland Land Bank and Land For Maine’s Future and partners like Portland Trails.</li>
          </ul>
        </div>

        <h4 class="text-xl mt-4 mb-1 font-semibold">Do you have an opinion or a question about this development?</h4>
        <p class="text-lg mb-2"> You can share your thoughts in the following ways:</p>
        <ol class="list-decimal list-inside space-y-2 text-lg pl-4 mb-6">
          <li>Email Zachary Powell (<a href="mailto:zpowell@portlandmaine.gov" class="text-blue-500 underline hover:text-blue-700">zpowell@portlandmaine.gov</a>) at Portland Planning to provide your public comment to the Planning Board.</li>
          <ul class="list-disc list-inside pl-8">
    <li>Address emails as “To Members of the Planning Board” and reference permit number PL-002454-2023.</li>
  </ul>
          <li>Write letters-to-the-editor to local news outlets like Portland Press Herald and Bangor Daily News with your opinion of the development.</li>
          <li>Get in touch with your city councilor to voice any concerns you may have:
            <ul class="list-disc list-inside pl-4">
              <li>D1: Anna Trevorrow (<a href="mailto:atrevorrow@portlandmaine.gov" class="text-blue-500 underline hover:text-blue-700">atrevorrow@portlandmaine.gov</a>)</li>
              <li>D2: Victoria Pelletier (<a href="mailto:vpelletier@portlandmaine.gov" class="text-blue-500 underline hover:text-blue-700">vpelletier@portlandmaine.gov</a>)</li>
              <li>D3: Regina Phillips (<a href="mailto:rphillips@portlandmaine.gov" class="text-blue-500 underline hover:text-blue-700">rphillips@portlandmaine.gov</a>)</li>
              <li>D4: Anna Bullett (<a href="mailto:abullett@portlandmaine.gov" class="text-blue-500 underline hover:text-blue-700">abullett@portlandmaine.gov</a>)</li>
              <li>D5: Kate Sykes (<a href="mailto:ksykes@portlandmaine.gov" class="text-blue-500 underline hover:text-blue-700">ksykes@portlandmaine.gov</a>)</li>
            </ul>
          </li>
          <li>Attend the next planning board meeting for this development (Date, time, and location will be posted here when scheduled).</li>
        </ol>
        <p class="text-lg mb-4">Looking for more information? Looking to get involved? Email <a href="mailto:ProtectPresumpscot@gmail.com" class="text-blue-500 underline hover:text-blue-700">ProtectPresumpscot@gmail.com</a></p>

        </div>
        
      </div>
    </section>
    <!-- Footer -->
    <footer class="text-center w-full flex flex-col sm:flex-row items-center justify-between bg-stone-200 text-stone-800 px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
    <p class="text-lg flex items-center mb-2 sm:mb-0"><i class="fas fa-map-marker-alt mr-2"></i> Protect Presumpscot</p>
    <p class="text-lg sm:ml-6 italic text-center sm:text-left">A conservation oriented nonprofit in Portland, Maine</p>
</footer>



</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
      modalImage: '',
      images: [
        'gallery/1.jpeg',
        'gallery/2.jpeg',
        'gallery/3.jpeg'
      ],
      isGalleryModalOpen: false,
      galleryModalImage: ''
    };
  },
  methods: {
    openModal(src) {
      this.modalImage = src;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.modalImage = '';
    },
    openGalleryModal(image) {
      this.galleryModalImage = image;
      this.isGalleryModalOpen = true;
    },
    closeGalleryModal() {
      this.isGalleryModalOpen = false;
      this.galleryModalImage = '';
    }
  }
};
</script>



<style scoped>
.modal {
  display: none;
}
.modal.active {
  display: flex;
}

.bg-radial-gradient {
        background: radial-gradient(circle, rgb(255, 255, 255), rgba(255, 255, 255, 0.49) 100%);
    }
</style>

